import { StatusMap } from './../../models/status-map';
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, catchError, tap } from "rxjs/operators";
import { AuthState } from "src/app/models/_core/auth-state";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";
import { SurgiProgressStatus } from "src/app/models/surgi-progress-status";
import { SurgiProgressEvent } from "src/app/models/surgi-progress-event";
import { BhStepperComponent } from "src/app/components/_core/bh-stepper/bh-stepper.component";
import { Person } from "src/app/models/person";
import { CareTrackerRoute } from "src/app/models/care-tracker-route";
import { StatusMapService } from "../status-map/status-map.service";
import { Patient } from 'src/app/models/patient';
import moment from 'moment';
@Injectable({
  providedIn: "root",
})
export class SurgicalStatusService {
  env = environment;
  person: Person;
  currentStep: SurgiProgressEvent;
  currentRoute: CareTrackerRoute;
  currentStatusSubject = new BehaviorSubject<SurgiProgressEvent>(null);
  currentPersonSubject = new BehaviorSubject<Patient>(null);
  progressEvents: SurgiProgressEvent[] = [
    {
      counter: 0,
      eventLabel: 'Scheduled',
      eventDescription: `Procedure is scheduled but ::firstName:: has not arrived.`,
      progressText:
        ".",
      progressTime: "3:25 PM",
      image: "/assets/time.png",
      showOnStepper: true,
      stepperStep: {
        ionIconName: "",
        name: "Scheduled",
        progress: 0,
        isCurrentStep: true,
      },
    },
    {
      counter: 1,
      eventLabel: 'In Pre-Op',
      eventDescription: `::firstName:: is completing paperwork and being prepared for their procedure.`,
      progressText:
        "is completing pre-operation paperwork and being prepared for surgery.",
      progressTime: "3:25 PM",
      image: "/assets/registration.png",
      showOnStepper: true,
      stepperStep: {
        ionIconName: "",
        name: "In Pre-Op",
        progress: 0,
        isCurrentStep: true,
      },
    },
    {
      counter: 2,
      eventLabel: 'In Surgery',
      eventDescription: `::firstName:: is undergoing their procedure.`,
      progressText: "is in the operating room undergoing their procedure.",
      progressTime: "5:25 PM",
      image: "/assets/heart.png",
      showOnStepper: true,
      stepperStep: {
        ionIconName: "",
        name: "In Surgery",
        progress: 0,
        isCurrentStep: false,
      },
    },
    {
      counter: 3,
      eventLabel: 'In PACU',
      eventDescription: `::firstName:: is in the PACU where they will recover from surgery.`,
      progressText: "is in the PACU where they will recover from surgery.",
      progressTime: "8:25 PM",
      image: "/assets/recovery.png",
      showOnStepper: true,
      stepperStep: {
        ionIconName: "",
        name: "In PACU",
        progress: 0,
        isCurrentStep: false,
      },
    },
    {
      counter: 4,
      eventLabel: 'Contacting Family',
      eventDescription: `Please call us for next steps: ::locPhoneNumber::`,
      progressText: "",
      progressTime: "",
      showOnStepper: true,
      stepperStep: {
        ionIconName: "",
        name: "Contacting Family",
        progress: 0,
        isCurrentStep: false,
      },
    },
    {
      counter: 5,
      eventLabel: 'Patient Left Unit',
      eventDescription: `::firstName:: has left the care unit.`,
      progressText: "",
      progressTime: "",
      showOnStepper: false,
      stepperStep: {
        ionIconName: "",
        name: "Patient Left unit",
        progress: 0,
        isCurrentStep: false,
      },
    },
  ];

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private statusService: StatusMapService
  ) { }
  // https://mobiledev.api.baystatehealth.org/surg-stat/status?caseNumber=CSC-2021-72&lastName=surginet&dob=01-apr-1972
  // getSurgicalStatus(caseNumber, lastName, dob): Observable<SurgiProgressStatus> {
  //   const url = `${this.env.apiUrl}/status?caseNumber=${caseNumber}&lastName=${lastName}&dob=${dob}`;
  //   return this.http.get(url)
  //     .pipe(
  //       tap((res: any) => {
  //         // Check for error messages
  //         if (res.x_status === 'E') {
  //           this.notifications.handleError(res, 'Error');
  //         }
  //         return res;
  //       })
  //     );
  // }

  getSurgicalStatus(): Observable<SurgiProgressStatus> {
    const url = `../../../assets/json-test-files/test-surgi.json`;
    return this.http.get(url).pipe(
      tap((res: any) => {
        // Check for error messages
        if (res.x_status === "E") {
          this.notifications.handleError(res, "Error");
        }
        return res;
      })
    );
  }

  getAllSurgicalCases() {
    //const url = `${environment.apiUrl}/care-tracking/rosters/${rosSeq}/shifts/${shfSeq}/role-groups`;
    const url = `../../../assets/json-test-files/test-cases.json`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "role-group-service.getAllRoleGroups(): " + url
        );
        return of(err);
      })
    );
  }

  setProgressEvents(patient: Patient): Promise<boolean> {
    // const matches = statusMaps.filter(
    //   (s) => s..filter((i) => i === surgicalStatus).length > 0
    // );
    console.log('setProgressEvents: patient: ', patient);
    let currentStatusMap: StatusMap = null;
    if (patient.smSeq === 0) {
      currentStatusMap = patient.statusMaps.find(sm => sm.statusStep === 0);
      patient.smSeq = (currentStatusMap !== undefined) ? currentStatusMap.smSeq : undefined;
    } else {
      currentStatusMap = patient.statusMaps.find(sm => patient.smSeq === sm.smSeq);
    }
    console.log('setProgressEvents: currentStatusMap: ', currentStatusMap);
    this.progressEvents.forEach((e, i) => {
      e.stepperStep.isCurrentStep = false;
      e.stepperStep.progress = i < currentStatusMap.statusStep ? 100 : 0;
      const statusMap = patient.statusMaps.find(sm => sm.statusStep === e.counter && patient.smSeq === sm.smSeq);
      console.log('setProgressEvents: statusMap: ', statusMap);
      if (statusMap !== undefined) {
        e.progressText = statusMap.statusDescription;
        e.stepperStep.name = statusMap.status;
        e.stepperStep.isCurrentStep = statusMap.smSeq === patient.smSeq;
        const firstName = patient.firstName.substring(0, 1)
          + patient.firstName.toLowerCase().substring(1, patient.firstName.length);
        e.progressText = e.progressText.replace('{firstName}', firstName);
        const scheduledDate = moment(patient.serviceDate, 'MM/DD/YYYY hh:mm a').format('M/D/YYYY h:mm a');
        e.progressText = e.progressText.replace('{scheduleDateTime}', scheduledDate);
      }
    });
    console.log('progressEvents', this.progressEvents);
    return Promise.resolve(true);
  }

  // getProgressStep(statusMap: StatusMap, patient: Patient): Promise<boolean> {
  //   try {
  //     console.log('getProgressStep: ', this.progressSteps, statusMap);
  //     this.progressSteps.forEach(s => {
  //       s.stepperStep.isCurrentStep = false;
  //       if (s.counter === statusMap.statusStep) {
  //         s.progressText = statusMap.statusDescription;
  //         s.stepperStep.name = statusMap.status;
  //         s.stepperStep.isCurrentStep = true;
  //         s.progressTime = patient.statusDate;
  //         const firstName = patient.firstName.substring(0, 1)
  //           + patient.firstName.toLowerCase().substring(1, patient.firstName.length);
  //         s.progressText = s.progressText.replace('{firstName}', firstName);

  //       }
  //     });
  //     return Promise.resolve(true);
  //   } catch (err) {
  //     throw err;
  //   }
  // }

  setProgressStep(currentStepNumber: number): SurgiProgressEvent {
    return this.progressEvents[currentStepNumber - 1];
  }

  showFailedAlert() {
    this.notifications.showAlert(
      "We could not verify the patient's identity",
      "The information provided does not match the associated patient. Please recheck the information provided.",
      "danger"
    );
  }

  setStatusSubject(surgiStep: SurgiProgressEvent) {
    this.currentStatusSubject.next(surgiStep);
  }

  getStatusSubject(): SurgiProgressEvent {
    return this.currentStatusSubject.getValue();
  }

  setPersonSubject(patient: Patient) {
    this.currentPersonSubject.next(patient);
  }

  getPersonSubject(): Person {
    return this.currentPersonSubject.getValue();
  }
}
