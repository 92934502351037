import { HelperUtilitiesService } from './../helper-utilities/helper-utilities.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { OneSignal } from '@ionic-native/onesignal/ngx';

/**
 * ID: bh-push-notifications-service
 * Name: BH Push Notifications Service
 * Description: Service used for handling push notifications
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - DW - v1: Initial dev
 */
@Injectable({
  providedIn: 'root'
})
export class PushService {
  env = environment;

  constructor(
    public http: HttpClientModule,
    public platform: Platform,
    private oneSignal: OneSignal,
    private helpers: HelperUtilitiesService
  ) { }
  init() {
    if (this.helpers.isNotBrowser) {
      console.log('--In push -Non Cordova no notifications-- ');
    } else {
      console.log('--In push-- ');
      // Initialise plugin with OneSignal service
      // To install OneSignal with Cordova:
      //    sudo gem install cocoapods
      //    pod repo update
      //    ionic cordova plugin add onesignal-cordova-plugin --save
      //    npm install --save @ionic-native/onesignal

      // To install OneSignal with Capacitor:
      //  npm install onesignal-cordova-plugin
      //  npm install @ionic-native/onesignal
      //  ionic cap sync
      const notificationOpenedCallback = (jsonData) => {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      };


      this.oneSignal.startInit(this.env.pushId)
        .handleNotificationOpened(notificationOpenedCallback)
        .endInit();

      // Retrieve the OneSignal user id and the device token
      this.oneSignal.getIds().then((ids) => {
        console.log('getIds: ' + JSON.stringify(ids));
      });

      this.oneSignal.handleNotificationReceived().subscribe((msg) => {
        // Log data received from the push notification service
        // console.log('Notification received');
        console.dir(msg);
      });

      this.oneSignal.handleNotificationOpened().subscribe((msg) => {
        // Log data received from the push notification service
        // console.log('Notification opened');
        console.dir(msg);
      });
      // End plugin initialisation
      this.oneSignal.endInit();
    }
  }
}
