// BH Starter Version *** DONT CHANGE ***
// Version: 5.0.11

export const environment = {
  // Environment Name
  env: 'DEV',

  // Is Production Environment?
  production: false,

  // App Name/ID
  appName: 'PATIENT',

  // App Description (full name)
  appDescription: 'Patient Apps',

  // App Description (full name)
  appDescriptionCT: 'Care Tracking',

  // App Version Number
  appVersion: '5.2.0',

  // App's REST Service API Root URL
  apiUrl: 'https://mobiledev.api.baystatehealth.org/patient',

  // App's REST Service API Care Tracking Root URL
  apiCTUrl: 'https://mobiledev.api.baystatehealth.org/caretracking',

  // App's Uploaded Files Root URL
  filesUrl: 'https://dev.caseshare.apps.baystatehealth.org',

  // App's Push Notifications App ID for OneSignal
  pushId: '',

  // Verlocker API URL
  verlockerUrl: 'https://mobiledev.api.baystatehealth.org/verlocker',

  // BH AppStore Deep Link URL
  appstoreUrl: 'https://mobile.baystatehealth.org/appstore/',

  // Reset password link URL
  resetPwdUrl: 'https://baystatehealth.service-now.com/nav_to.do?uri=%2F$pwd_reset.do%3Fsysparm_url%3Dpassword_reset',

  // Change password security questions link URL
  changePwdQuestionsUrl: 'https://baystatehealth.service-now.com/b_sp?id=pwd_reset_enrollment',

  // Set default theme: auto, light, dark
  // Auto will select theme based on browser's preferred theme
  theme: 'auto',

  // Store token in local storage to remember user?
  // Patient/Secure Apps should set this to false
  storeToken: true,

  // Allow Biometrics to login
  // This will allow users to login using biometrics (fingerprint, Touch ID or Face ID)
  allowBiometrics: false,

  // Require PIN code enabled on device
  // Patient/Secure Apps will require that the PIN code is enabled
  // If PIN is not in use, app will block user
  requirePin: true,

  // Require timeout
  // Patient/Secure Apps will require that timeout is enabled
  // On timeout, user will be bumped
  requireTimeout: false,

  // Timeout threshold
  // Determine the number of seconds when timeout will occur
  // 15 minutes = 900000
  timeoutThreshold: 900000,

  //Firebase to test chat capabilities
  firebaseConfig: {
    apiKey: "AIzaSyDfi-hjkQDzNImb1r6QNx8KcFF9aESHoos",
    authDomain: "care-tracking-chat.firebaseapp.com",
    projectId: "care-tracking-chat",
    storageBucket: "care-tracking-chat.appspot.com",
    messagingSenderId: "78683712364",
    appId: "1:78683712364:web:7344c43247ab8b57f96149"
  },

  // Default Timed Message EMB ID
  defaultTimedMsgEmbId: 'CTNOSTATUSCHGTEXT',

  // WorkTips' Care Tracking Page
  ctWorkTipsPage: 'https://worktips.apps.baystatehealth.org/tabs/apps/0d90b3de-3c06-49f1-ae8a-51fba5af33b1'

};
