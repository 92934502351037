import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Follower } from '../../models/follower';
import { StatusMap } from '../../models/status-map';
import { NotificationsService } from '../_core/notifications/notifications.service';


@Injectable({
  providedIn: 'root'
})
export class StatusMapService {
  env = environment;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { 
    
  }
  getAllStatuses(ssId, locSeq) {
    const url = `${environment.apiCTUrl}/source-system/${ssId}/locations/${locSeq}/status-map`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sm-service.getAllStatuses(): " + url
        );
        return of(err);
      })
    );
  }

  getSingleStatus(ssId, locSeq, smSeq) {
    const url = `${environment.apiCTUrl}/source-system/${ssId}/locations/${locSeq}/status-map/${smSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sm-service.getSingleStatus(): " + url
        );
        return of(err);
      })
    );
  }

  createStatus(statusMap: StatusMap) {
    const url = `${environment.apiCTUrl}/source-system/${statusMap.ssId}/locations/${statusMap.locSeq}/status-map`;
    const body = statusMap;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sm-service.createStatus(): " + url
        );
        return of(err);
      })
    );
  }

  updateStatus(statusMap: StatusMap) {
    const url = `${environment.apiCTUrl}/source-system/${statusMap.ssId}/locations/${statusMap.locSeq}/status-map/${statusMap.smSeq}`;
    const body = statusMap;
    return this.http.put(url,body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sm-service.updateStatus(): " + url
        );
        return of(err);
      })
    );
  }

}
