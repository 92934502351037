import { CovidTestResultRouteGuardService } from './services/covid-test-result-route-guard/covid-test-result-route-guard.service';
import { LoginRouteGuardService } from 'src/app/services/_core/login-route-guard/login-route-guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';
import { CareTrackingRouteGuardService } from './services/care-tracking-route-guard/care-tracking-route-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'care-tracking/login',
    pathMatch: 'full',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/care-tracking-console/login-provider-portal/login-provider-portal.module').then(m => m.LoginProviderPortalPageModule)
  },
  // {
  //   path: '**',
  //   redirectTo: '/login'
  // },
  {
    path: 'help-forgot-pwd',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () =>
      import('./pages/_core/help-forgot-pwd/help-forgot-pwd.module').then(m => m.HelpForgotPwdPageModule)
  },
  {
    path: 'share',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/share/share.module').then(m => m.SharePageModule)
  },
  {
    path: 'help',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'help-no-pin',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: './pages/_core/help-no-pin/help-no-pin.module#HelpNoPinPageModule'
  },
  {
    path: 'errors',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: './pages/_core/errors/errors.module#ErrorsPageModule'
  },
  {
    path: 'errors-detail',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: './pages/_core/errors-detail/errors-detail.module#ErrorsDetailPageModule'
  },
  {
    path: 'check-for-updates',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'Check for Updates', roles: [] },
    loadChildren: () => import('./pages/_core/check-for-updates/check-for-updates.module').then( m => m.CheckForUpdatesPageModule)
  },
  {
    path: 'about',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'my-account',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/my-account/my-account.module').then( m => m.MyAccountPageModule)
  },
  {
    path: 'bh-input-text-editor',
    loadChildren: () => import('./components/_core/bh-input-text-editor/bh-input-text-editor.module').then( m => m.BhInputTextEditorPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/_core/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'covid-test-results/welcome',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Test Results: Welcome', roles: [] },
    loadChildren: () => import('./pages/covid-test-results/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'covid-test-results/:uniqueKey',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Test Results: Welcome', roles: [] },
    loadChildren: () => import('./pages/covid-test-results/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'covid-test-results/:uniqueKey/bad-link',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Test Results: Bad/Expired Link', roles: [] },
    loadChildren: () => import('./pages/covid-test-results/bad-link/bad-link.module').then(m => m.BadLinkPageModule)
  },
  {
    path: 'covid-test-results/:uniqueKey/declined',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Test Results: Declined', roles: [] },
    loadChildren: () => import('./pages/covid-test-results/declined/declined.module').then(m => m.DeclinedPageModule)
  },
  {
    path: 'covid-test-results/:uniqueKey/terms',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Test Results: Terms', roles: [] },
    loadChildren: () => import('./pages/covid-test-results/terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: 'covid-test-results/verification',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Test Results: Verification', roles: [] },
    loadChildren: () => import('./pages/covid-test-results/verification/verification.module').then(m => m.VerificationPageModule)
  },
  {
    path: 'covid-test-results/:uniqueKey/verification',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Test Results: Verification', roles: [] },
    loadChildren: () => import('./pages/covid-test-results/verification/verification.module').then(m => m.VerificationPageModule)
  },
  {
    path: 'covid-test-results/test-result',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Test Results: Test Result', roles: [] },
    loadChildren: () => import('./pages/covid-test-results/test-result/test-result.module').then(m => m.TestResultPageModule)
  },
  {
    path: 'covid-test-results/:uniqueKey/test-result',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'COVID-19 Test Results: Test Result', roles: [] },
    loadChildren: () => import('./pages/covid-test-results/test-result/test-result.module').then(m => m.TestResultPageModule)
  },
  {
    path: 'care-tracking/welcome',
    pathMatch: 'full',
    canActivate: [BhAnalyticsRouteGuardService, CareTrackingRouteGuardService],
    data: { pageName: 'Care Tracking: Home', roles: [] },
    loadChildren: () => import('./pages/care-tracking/self-reg-welcome/self-reg-welcome.module').then(m => m.SelfRegWelcomePageModule)
  },
  {
    path: 'care-tracking/register',
    canActivate: [BhAnalyticsRouteGuardService, CareTrackingRouteGuardService],
    data: { pageName: 'Care Tracking: Registration', roles: [] },
    loadChildren: () =>
    import('./pages/care-tracking/self-reg-form/self-reg-form.module').then(m => m.SelfRegFormPageModule)
  },
  {
    path: 'care-tracking/progress',
    canActivate: [BhAnalyticsRouteGuardService, CareTrackingRouteGuardService],
    data: { pageName: 'Care Tracking: Progress', roles: [] },
    loadChildren: () =>
    import('./pages/care-tracking/progress/progress.module').then(m => m.ProgressPageModule)
  },
  {
    path: 'p/:uniqueKey',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'Care Tracking: Progress', roles: [] },
    loadChildren: () =>
    import('./pages/care-tracking/progress/progress.module').then(m => m.ProgressPageModule)
  },
  // {
  //   path: 'p/:uniqueKey/:languageCode',
  //   canActivate: [BhAnalyticsRouteGuardService],
  //   data: { pageName: 'Care Tracking: Progress', roles: [] },
  //   loadChildren: () =>
  //   import('./pages/care-tracking/progress/progress.module').then(m => m.ProgressPageModule)
  // },
  {
    path: 'care-tracking/chat',
    canActivate: [BhAnalyticsRouteGuardService, CareTrackingRouteGuardService],
    data: { pageName: 'Care Tracking: Chat', roles: [] },
    loadChildren: () =>
    import('./pages/care-tracking/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'care-tracking/terms',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'Care Tracking: Terms', roles: [] },
    loadChildren: () => import('./pages/care-tracking/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'care-tracking/console/tracking',
    canActivate: [BhAnalyticsRouteGuardService,LoginRouteGuardService],
    data: { pageName: 'Care Tracking: console', roles: ['ADMIN', 'SYS_ADMIN'] },
    loadChildren: () => import('./pages/care-tracking-console/console/console.module').then( m => m.ConsolePageModule)
  },
  {
    path: 'care-tracking/console/tracking/:locKey',
    canActivate: [BhAnalyticsRouteGuardService,LoginRouteGuardService],
    data: { pageName: 'Care Tracking: console', roles: ['ADMIN', 'SYS_ADMIN'] },
    loadChildren: () => import('./pages/care-tracking-console/console/console.module').then( m => m.ConsolePageModule)
  },
  {
    path: 'care-tracking/console/manage',
    canActivate: [BhAnalyticsRouteGuardService,LoginRouteGuardService],
    data: { pageName: 'Care Tracking: console', roles: ['SYS_ADMIN'] },
    loadChildren: () => import('./pages/care-tracking-console/manage/manage.module').then( m => m.ManagePageModule)
  },
  {
    path: 'care-tracking/console/manage/:tabId',
    canActivate: [BhAnalyticsRouteGuardService,LoginRouteGuardService],
    data: { pageName: 'Care Tracking: console', roles: ['SYS_ADMIN'] },
    loadChildren: () => import('./pages/care-tracking-console/manage/manage.module').then( m => m.ManagePageModule)
  },
  {
    path: 'care-tracking/console/dashboard',
    canActivate: [BhAnalyticsRouteGuardService,LoginRouteGuardService],
    data: { pageName: 'Care Tracking: Dashboard', roles: ['ADMIN', 'SYS_ADMIN'] },
    loadChildren: () => import('./pages/care-tracking-console/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'care-tracking/console/dashboard/:locKey',
    canActivate: [BhAnalyticsRouteGuardService,LoginRouteGuardService],
    data: { pageName: 'Care Tracking: Dashboard', roles: ['ADMIN', 'SYS_ADMIN'] },
    loadChildren: () => import('./pages/care-tracking-console/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'followers',
    loadChildren: () => import('./pages/care-tracking-console/followers/followers.module').then( m => m.FollowersPageModule)
  },
  // {
  //   path: 'login-provider-portal',
  //   loadChildren: () => import('./pages/care-tracking-manage/login-provider-portal/login-provider-portal.module').then( m => m.LoginProviderPortalPageModule)
  // },
  {
    path: 'chat',
    loadChildren: () => import('./pages/care-tracking-console/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'language-selection',
    loadChildren: () => import('./pages/language-selection/language-selection.module').then( m => m.LanguageSelectionPageModule)
  },
  {
    path: 'i',
    loadChildren: () => import('./pages/care-tracking/invitation/invitation.module').then( m => m.InvitationPageModule)
  },
  {
    path: 'i/:uniqueKey',
    loadChildren: () => import('./pages/care-tracking/invitation/invitation.module').then( m => m.InvitationPageModule)
  },
  // {
  //   path: 'i/:uniqueKey/:langaugeCode',
  //   loadChildren: () => import('./pages/care-tracking/invitation/invitation.module').then( m => m.InvitationPageModule)
  // },
  // {
  //   path: 'i/:uniqueKey/:langaugeCode/identity',
  //   loadChildren: () => import('./pages/care-tracking/invitation-identity/invitation-identity.module').then( m => m.InvitationIdentityPageModule)
  // },
  {
    path: 'i/:uniqueKey/identity',
    loadChildren: () => import('./pages/care-tracking/invitation-identity/invitation-identity.module').then( m => m.InvitationIdentityPageModule)
  },
  {
    path: 'i/:uniqueKey/confirmation',
    loadChildren: () => import('./pages/care-tracking/invitation-confirmation/invitation-confirmation.module').then( m => m.InvitationConfirmationPageModule)
  },
  // {
  //   path: 'i/:uniqueKey/:langaugeCode/confirmation',
  //   loadChildren: () => import('./pages/care-tracking/invitation-confirmation/invitation-confirmation.module').then( m => m.InvitationConfirmationPageModule)
  // },
  {
    path: 'i/:uniqueKey/bad-key',
    loadChildren: () => import('./pages/care-tracking/bad-key/bad-key.module').then( m => m.BadKeyPageModule)
  },
  // {
  //   path: 'i/:uniqueKey/:langaugeCode/bad-key',
  //   loadChildren: () => import('./pages/care-tracking/bad-key/bad-key.module').then( m => m.BadKeyPageModule)
  // },
  {
    path: 'status-maps',
    loadChildren: () => import('./pages/care-tracking-console/status-maps/status-maps.module').then( m => m.StatusMapsPageModule)
  },
  {
    path: 'feedback-survey',
    loadChildren: () => import('./pages/care-tracking/feedback-survey/feedback-survey.module').then( m => m.CareTrackingFeedbackSurveyPageModule)
  },
  {
    path: 'location-settings',
    loadChildren: () => import('./pages/care-tracking-console/location-settings/location-settings.module').then( m => m.LocationSettingsPageModule)
  },
  {
    path: 'waiting-areas',
    loadChildren: () => import('./pages/care-tracking-console/waiting-areas/waiting-areas.module').then( m => m.WaitingAreasPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/care-tracking-console/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'location-properties',
    loadChildren: () => import('./pages/care-tracking-console/location-properties/location-properties.module').then( m => m.LocationPropertiesPageModule)
  },
  {
    path: 'metrics',
    loadChildren: () => import('./pages/care-tracking-console/metrics/metrics.module').then( m => m.MetricsPageModule)
  },
  {
    path: 'graph',
    loadChildren: () => import('./pages/care-tracking-console/graph/graph.module').then( m => m.GraphPageModule)
  },
  {
    path: 'dashboard-detail',
    loadChildren: () => import('./pages/care-tracking-console/dashboard-detail/dashboard-detail.module').then( m => m.DashboardDetailPageModule)
  },
  // {
  //   path: 'communication',
  //   loadChildren: () => import('./pages/covid-test-results/communication/communication.module').then( m => m.CommunicationPageModule)
  // },
  // {
  //   path: 'progress',
  //   loadChildren: () => import('./pages/care-tracking/progress/progress.module').then( m => m.ProgressPageModule)
  // },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
