import { Messaging } from './../../models/messaging';
import { NavController } from '@ionic/angular';
import { CovidTestResultState } from './../../models/covid-test-result-state';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { StepperStep } from 'src/app/models/_core/stepper-step';

/**
 * App State Service: Used to track the current state the application and app-specific constants.
 */
@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  // Observable application title that updates all subscribed pages.
  appTitle: BehaviorSubject<string> = new BehaviorSubject('');

  // Apple AppStore URL
  iosAppStoreUrl = 'https://itunes.apple.com/us/app/mybaystatehealth/id1149098574?mt=8';

  // Google Play URL
  googlePlayStoreUrl = 'https://play.google.com/store/apps/details?id=org.baystatehealth.mybaystatehealth';

  // UUID Version 4 Pattern
  uuidPattern = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

  // Date Input Pattern
  // dateInputFormat = /^[0,1]?\d{1}\/(([0-2]?\d{1})|([3][0,1]{1}))\/(([1]{1}[9]{1}[9]{1}\d{1})|([2-9]{1}\d{3}))$/;
  dateInputFormat = new RegExp(/^(0?[1-9]|1[0-2])\/(0?[1-9]|1[0-9]|2[0-9]|3[01])\/\d{4}$/);

  // Covid Test Result Flow
  covidTestResultState: CovidTestResultState = {
    step: 'welcome'
  };

  covidTestResultMessaging: Messaging[] = [];

  constructor(
    private navCtrl: NavController
  ) { }

  /**
   * Gets App Title
   * Recommend subscribing to appTitle directly
   */
  getAppTitle(): string {
    return this.appTitle.getValue();
  }

  /**
   * Updates App Title with provided string
   * @param title Title string to display throughout the app headers and page title
   */
  setAppTitle(title: string) {
    document.title = title + '  - Baystate Health';
    this.appTitle.next(title);
  }

  // TODO: Should be converted to route-guard.
  /**
   * Compares appState-defined step with the page's designated (current) step. Must match or user is redirected to appropriate page. 
   * @param currentStep Page-defined step
   */
  validateCurrentStep(pageStep): void {
    // Validate Unique Key Format
    const uniqueKey = this.covidTestResultState.uniqueKey;
    const regExp = new RegExp(this.uuidPattern);
    const uniqueKeyFormatValid = regExp.test(uniqueKey);
    if (!uniqueKeyFormatValid) {
      console.log('validateCurrentStep: invalid key', uniqueKey, uniqueKeyFormatValid);
      this.navCtrl.navigateRoot(`/tabs/covid-test-results/as/bad-link`);
      return;
    }

    // Check current Step
    const step = this.covidTestResultState.step;
    if (step !== pageStep) {
      console.log('validateCurrentStep: current step mismatch', step, pageStep);
      switch (step) {
        case 'welcome':
          this.navCtrl.navigateRoot(`/tabs/covid-test-results/${uniqueKey}`);
          break;

        case 'verification':
          this.navCtrl.navigateRoot(`/tabs/covid-test-results/${uniqueKey}/verification`);
          break;

        case 'test-result':
          this.navCtrl.navigateRoot(`/tabs/covid-test-results/${uniqueKey}/test-result`);
          break;
      }
    }
  }

}
