import { UserAccess } from './../../models/user-access';
import { User } from 'src/app/models/user';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AuthState } from 'src/app/models/_core/auth-state';
import { NotificationsService } from '../_core/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  env = environment;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  getAll(activeOnly: boolean): Observable<User[]> {
    const activeOnlyParam = activeOnly ? '?activeOnly=1' : '';
    const url = `${this.env.apiUrl}/getUsersAll${activeOnlyParam}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getAllUsers() {
    const url = `${environment.apiCTUrl}/users`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "users-service.getAllUsers(): " + url
        );
        return of(err);
      })
    );
  }

  getById(userId): Observable<any> {
    const url = `${this.env.apiCTUrl}/users/${userId}`;

    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getByLocSeq(locSeq: number) {
    const url = `${environment.apiCTUrl}/users?locSeq=${locSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "users-service.getAllUsers(): " + url
        );
        return of(err);
      })
    );
  }

  getUserAuthType(recordId): Observable<User> {
    const url = `${this.env.apiCTUrl}/getUserAuthType`;
    const body = {
      id: recordId
    };

    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  create(user: User) {
    if (user.fullName.length > 0) {
      const url = `${this.env.apiCTUrl}/users`;

      return this.http.post(url, user).pipe(
        map((data: any) => {
          return data;
        })
      );
    }
  }

  update(user: User) {
    if (user.fullName.length > 0) {
      const url = `${this.env.apiCTUrl}/users/${user.userId}`;
      return this.http.put(url, user).pipe(
        map((data: any) => {
          return data;
        })
      );
    }
  }

  getUserAccess(userId: string): Observable<any> {
    const url = `${this.env.apiCTUrl}/users/${userId}/access`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getAccessByLocSeq(locSeq: number): Observable<any> {
    const url = `${this.env.apiCTUrl}/users/access?locSeq=${locSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  addAccess(access: UserAccess): Observable<any> {
    const url = `${this.env.apiCTUrl}/users/${access.userId}/access`;
    return this.http.post(url, access).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateAccess(access: UserAccess): Observable<any> {
    const url = `${this.env.apiCTUrl}/users/${access.userId}/access/${access.uacSeq}`;
    return this.http.put(url, access).pipe(
      map((data: any) => {
        return data;
      })
    );
  }


}
