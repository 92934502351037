import { NavController } from '@ionic/angular';
import { AppStateService } from 'src/app/services/app-state/app-state.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, ActivatedRoute, Router, NavigationEnd, ActivationStart, GuardsCheckEnd } from '@angular/router';
import { CareTrackerRoute } from 'src/app/models/care-tracker-route';
import { SurgicalStatusService } from '../surgical-status/surgical-status.service';

@Injectable({
  providedIn: 'root'
})
export class CareTrackingRouteGuardService implements CanActivate {

  redirectUrl = '';
  currentRoute: CareTrackerRoute;
  constructor(
    private appStateService: AppStateService,
    private navCtrl: NavController,
    private router: Router,
    private surgicalStatusService: SurgicalStatusService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        const snapshot = event.snapshot;
      }
    });
  }
  /**
 * Checks if user is logged in and if not, reroutes to login
 * @param route Activated Route Snapshot
 */
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const routeURL = route.url.join('/');
    const allowNavigate = this.validateCurrentStep(routeURL);
    if (!allowNavigate) {
      this.surgicalStatusService.currentRoute = 'care-tracking/welcome';
      return this.navCtrl.navigateRoot('/care-tracking/welcome');
    }
    return true;
  }

  validateCurrentStep(targetRoute: CareTrackerRoute): boolean {
    return (targetRoute === this.surgicalStatusService.currentRoute);
  }

}
